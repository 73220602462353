import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { STORAGE } from './constants';

/**
 * Full store persistence
 */
const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE.STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORAGE.STATE, serializedState);
  } catch {
    // ignore write errors
  }
};

const persistedState = loadState();

export default (history, initialState = persistedState) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const reactRouterMiddleware = routerMiddleware(history);
  const enhancers = composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(reactRouterMiddleware)
  );
  const store = createStore(reducer, initialState, enhancers);

  // Save to localStorage
  store.subscribe(() => {
    saveState({
      settings: store.getState().settings,
      graph: store.getState().graph
    });
  });

  return store;
};
