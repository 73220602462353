import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import styled from "styled-components";
import SideNavLink from "./SideNavLink";
import { COLOUR, STORAGE } from "../../../constants";
import { version } from "../../../../package.json";
import { ClientContext } from "../../../context/ClientContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOUR.MENU_DARK};
  color: white;
  height: 100%;
  width: 100%;
  text-align: left;
  a {
    color: white;
    text-decoration: none;
  }
`;

const Spacer = styled.div`
  padding: 16px;
  flex: 1;
`;

const Version = styled.div`
  padding: 16px 16px 16px 24px;
  font-size: 0.9em;
  color: #666;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; // Place icon on right of panel
  padding: 0 16px;
  fill: white;
  height: 48px; // Same as header height
  & :hover {
    cursor: pointer;
  }
`;

const SideNav = ({ history, closeHandler, resetSensorFilter, settings }) => {
  const { setClient } = useContext(ClientContext);
  const path = history.location.pathname || "/";
  const { organisation } = settings;

  const isActive = (route) => route === path;
  const isCareHome = organisation.type === STORAGE.CARE_HOME;
  let clientDescription = isCareHome ? "Residents" : "Clients";
  if (organisation && organisation.id === "43") {
    clientDescription = "Locations";
  }

  const resetFilterAndClose = () => {
    resetSensorFilter();
    setClient(null);
    closeHandler();
  };

  return (
    <Container>
      <CloseIcon onClick={closeHandler}>
        <Icon path={mdiClose} title="Menu" size={1} />
      </CloseIcon>
      {isCareHome && (
        <SideNavLink
          text="Dashboard"
          link="/"
          active={isActive("/")}
          closeHandler={resetFilterAndClose}
        />
      )}
      {isCareHome && (
        <SideNavLink
          text="Alert Summary"
          link="/resident-alerts"
          active={isActive("/resident-alerts")}
          closeHandler={resetFilterAndClose}
        />
      )}
      <SideNavLink
        text={clientDescription}
        link="/clients"
        active={isActive("/clients")}
        closeHandler={resetFilterAndClose}
      />
      {/* <SideNavLink
        text="Room Layout"
        link="/room"
        active={isActive('/room')}
        closeHandler={closeHandler}
      /> */}
      <Spacer />
      <SideNavLink
        text="Privacy Policy"
        link="/privacy"
        closeHandler={closeHandler}
      />
      <SideNavLink text="Logout" link="/logout" closeHandler={closeHandler} />
      <Version>{`Version ${version}`}</Version>
    </Container>
  );
};

SideNav.propTypes = {
  history: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  resetSensorFilter: PropTypes.func.isRequired,
};

export default SideNav;
