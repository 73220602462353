import {
  mdiAccountCircle,
  mdiAccountSwitch,
  mdiApps,
  mdiClose,
  mdiDoorClosed,
  mdiDoorOpen,
  mdiFilter,
  mdiFilterOutline,
  mdiHotel,
  mdiMotionSensor,
  mdiRadioboxMarked,
  mdiShower,
  mdiSilverwareForkKnife,
  mdiSofa,
  mdiSeatOutline,
  mdiThermometer,
  mdiWaterOutline,
  mdiWaves,
  mdiPhoneRotateLandscape,
  mdiBell,
  mdiBellOutline,
  mdiBellOffOutline,
  mdiLightbulbOnOutline,
  // mdiBattery10,
  // mdiBattery20,
  // mdiBattery30,
  // mdiBattery40,
  // mdiBattery50,
  // mdiBattery60,
  // mdiBattery70,
  // mdiBattery80,
  // mdiBattery90,
  // mdiBattery,
} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  padding: 11px;
`;

const size = { width: '24px', height: '24px' };
const sizeLarge = { width: '48px', height: '48px' };

// Wrapper class to place a 24x24 icon inside a 48x48px box
export const BoxIcon = (props) => {
  const { size, ...resizedProps } = props;
  const newProps = { ...resizedProps, width: '24px', height: '24px' };
  return (
    <IconWrapper>
      <Icon {...newProps} />
    </IconWrapper>
  );
};

export const IconClose = () => (
  <Icon path={mdiClose} title="Close" style={size} />
);

export const IconDoorOpen = () => (
  <Icon path={mdiDoorOpen} title="Door open" style={size} />
);

export const IconDoorClosed = () => (
  <Icon path={mdiDoorClosed} title="Door closed" style={size} />
);

// Motion sensors
export const IconKitchen = () => (
  <Icon path={mdiSilverwareForkKnife} title="Kitchen" style={size} />
);

export const IconLivingRoom = () => (
  <Icon path={mdiSofa} title="Living Room" style={size} />
);

export const IconBedroom = () => (
  <Icon path={mdiHotel} title="Bedroom" style={size} />
);

export const IconBathroom = () => (
  <Icon path={mdiShower} title="Bathroom" style={size} />
);

export const IconTemperature = () => (
  <Icon path={mdiThermometer} title="Temperature" style={size} />
);

export const IconHumidity = () => (
  <Icon path={mdiWaterOutline} title="Humidity" style={size} />
);

export const IconPanicButton = () => (
  <Icon path={mdiRadioboxMarked} title="Panic button" style={size} />
);

export const IconFlood = () => (
  <Icon path={mdiWaves} title="Leak" style={size} />
);

export const IconChair = () => (
  <Icon path={mdiSeatOutline} title="Chair sensor" style={size} />
);

export const IconMotion = () => (
  <Icon path={mdiMotionSensor} title="Motion" style={size} />
);

export const IconGrid = () => <Icon path={mdiApps} title="All" style={size} />;

export const IconAccountSwitch = () => (
  <Icon path={mdiAccountSwitch} title="Switch client" style={size} />
);

export const IconAccount = () => (
  <Icon path={mdiAccountCircle} title="User profile" style={size} />
);

export const IconFilter = () => (
  <Icon path={mdiFilter} title="Filter applied" style={size} />
);

export const IconFilterOutline = () => (
  <Icon path={mdiFilterOutline} title="Filter" style={size} />
);

export const IconLight = () => (
  <Icon path={mdiLightbulbOnOutline} title="Rotate" style={size} />
);

export const IconRotateLandscape = () => (
  <Icon path={mdiPhoneRotateLandscape} title="Rotate" style={sizeLarge} />
);

export const IconAlarmOn = () => (
  <Icon path={mdiBellOutline} title="Rotate" style={sizeLarge} />
);

export const IconAlarmResponding = () => (
  <Icon path={mdiBell} title="Rotate" style={sizeLarge} />
);

export const IconAlarmOff = () => (
  <Icon path={mdiBellOffOutline} title="Rotate" style={sizeLarge} />
);

// export const IconBattery = (capacity) => {
//   const percent =
//   return (
//     <Icon path={mdiBellOffOutline} title="Rotate" style={sizeLarge} />
//   );
// }
