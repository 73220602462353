import gql from 'graphql-tag';

export const alertInstancesQuery = gql`
  query alertInstances {
    alertInstances {
      id
      type
      client_id
      tenant_id
      config
      location
      active
      createdAt
      updatedAt
    }
  }
`;

export const alertByIdQuery = gql`
  query alertById($id: Int!) {
    alertById(id: $id) {
      id
      type
      client_id
      tenant_id
      config
      location
      active
      createdAt
      updatedAt
    }
  }
`;

export const alertsByTypeQuery = gql`
  query alertsByType($type: String!) {
    alertsByType(type: $type) {
      id
      type
      client_id
      tenant_id
      config
      location
      active
      createdAt
      updatedAt
    }
  }
`;

export const alertsByClientQuery = gql`
  query alertsByClient($clientId: Int!) {
    alertsByClient(clientId: $clientId) {
      id
      type
      client_id
      tenant_id
      config
      location
      active
      createdAt
      updatedAt
    }
  }
`;

export const alertDefinitionsQuery = gql`
  query alertDefinitions {
    alertDefinitions {
      type
      title
      selectLocation
      notificationText
      alwaysActive
      params {
        name
        type
        units
        default
        min
        max
      }
      deviceStates {
        transition
        type
        location
        label
      }
    }
  }
`;

export const alertByIdWithDefinitionsQuery = gql`
  query alertByIdWithDefinitions($id: Int!, $clientId: Int!) {
    sensors(clientId: $clientId) {
      id
      title
      installed_at
      dev_eui
      ipso_tag
      sensor_type
    }
    sleepingTime(clientId: $clientId) {
      start_time
      end_time
    }
    alertById(id: $id) {
      id
      type
      client_id
      tenant_id
      config
      location
      active
      createdAt
      updatedAt
    }
    alertDefinitions {
      type
      title
      selectLocation
      notificationText
      alwaysActive
      params {
        name
        type
        units
        default
        min
        max
      }
      deviceStates {
        transition
        type
        location
        label
      }
    }
  }
`;

// This query is used by the AlertsView page to collate
// info about the current client's sensors and alerts.
export const alertDefinitionsWithAllInstancesQuery = gql`
  query alertDefinitionsWithAllInstancesQuery($clientId: Int!) {
    sensors(clientId: $clientId) {
      id
      title
      installed_at
      dev_eui
      ipso_tag
      sensor_type
    }
    sleepingTime(clientId: $clientId) {
      start_time
      end_time
    }
    alertsByClient(clientId: $clientId) {
      id
      type
      location
      config
      active
    }
    alertDefinitions {
      type
      title
      selectLocation
      notificationText
      alwaysActive
      params {
        name
        type
        units
        default
        min
        max
      }
      deviceStates {
        transition
        type
        location
        label
      }
    }
  }
`;

export const sleepingTimeQuery = gql`
  query sleepingTimeQuery($clientId: Int!) {
    sleepingTime(clientId: $clientId) {
      start_time
      end_time
    }
  }
`;
