import gql from 'graphql-tag';

export const loginMutation = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;

export const loginEnhancedMutation = gql`
  mutation loginEnhanced($username: String!, $password: String!) {
    loginEnhanced(username: $username, password: $password) {
      id
      fullname
      role
      tenant
      organisationName
      token
      enhancedToken
    }
  }
`;
