import {
  SET_STATUS,
  SET_SELECTED_CLIENT,
  SET_SENSOR_FILTER,
  SET_DATE_RANGE,
  SET_ORGANISATION,
} from "../actions";
import { toggleSensorFilter } from "./transforms";
import { STORAGE } from "../constants";
import { getLastDay } from "../services/utils";

const initialState = {
  status: "running",
  selectedClient: null,
  sensorsFilter: [],
  dateRange: getLastDay(), // How far back to fetch data in graph view
  organisation: {
    id: null,
    type: STORAGE.DOMICILIARY,
    name: null,
    fullname: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATUS: {
      return { ...state, status: payload };
    }

    case SET_SELECTED_CLIENT: {
      return { ...state, selectedClient: payload };
    }

    case SET_SENSOR_FILTER: {
      const sensorsFilter = toggleSensorFilter(state.sensorsFilter, payload);

      return { ...state, sensorsFilter };
    }

    case SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: payload,
      };
    }

    case SET_ORGANISATION: {
      return { ...state, organisation: payload };
    }

    default:
      return state;
  }
};

/*
{
type: 'SET_STATUS', payload: { importStatus: 'RUNNING' }
}
*/
