import React, { createContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { userQuery } from '../graphql';
import { STORAGE } from '../constants';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const storedUser = localStorage.getItem(STORAGE.USER);
  const [token, setToken] = useState(localStorage.getItem(STORAGE.TOKEN));
  const initialUser = {
    currentUser: storedUser ? JSON.parse(storedUser) : null,
  };
  let data = initialUser;

  // Fetch info about current user from backend API and load into context
  try {
    const response = useQuery(userQuery, {
      skip: !token,
    });
    if (response.data) {
      data = response.data;
    }
    if (response.error) {
      console.log('ERROR: Unable to fetch current user info');
    }
  } catch (err) {
    console.log('UserContext ERROR');
  }

  const updateToken = (token) => setToken(token);

  return (
    <UserContext.Provider value={{ ...data.currentUser, updateToken }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
