import React, { createContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { clientsQuery } from '../graphql';
import { STORAGE } from '../constants';

export const ClientContext = createContext();

const initialData = { monitoring: [], client: null };

const ClientContextProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem(STORAGE.TOKEN));
  const storedClient = localStorage.getItem(STORAGE.CLIENT);
  const initialClient = storedClient ? JSON.parse(storedClient) : null;
  const [client, setClient] = useState(initialClient);

  // Fetch monitored clients from backend API and load into context
  let data = initialData;

  try {
    const response = useQuery(clientsQuery, {
      skip: !token,
    });
    if (response.data) {
      data = response.data;
    }
    if (response.error) {
      console.log('ERROR: Unable to fetch list of monitored clients.');
    }
  } catch (err) {
    console.log('ERROR');
  }

  // Function to persist and set selected client in context
  const setSelectedClient = (client) => {
    localStorage.setItem(STORAGE.CLIENT, JSON.stringify(client));
    setClient(client);
  };

  // Force context to reload after fresh login
  const updateToken = (token) => setToken(token);

  return (
    <ClientContext.Provider
      value={{
        clientList: data.monitoring,
        client,
        setClient: setSelectedClient,
        reload: updateToken,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientContextProvider;
