import gql from 'graphql-tag';

export const monitoringQuery = gql`
  query monitoring {
    monitoring {
      id
      client {
        id
        first_name
        last_name
        photo
        dashboard
        sensors_count
      }
      sensors {
        id
        title
        sensor_type
        installed_at
      }
    }
  }
`;
