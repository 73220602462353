import gql from 'graphql-tag';

export const signalsQuery = gql`
  query signals($clientId: Int, $limit: Int, $offset: Int, $filter: [Int]) {
    signals(
      clientId: $clientId
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      id
      sensor_id
      title
      sensor_type
      installed_at
      value
      description
      units
      battery_capacity
      microshare_timestamp
    }
  }
`;

export const lastMovementsQuery = gql`
  query lastMovements($clients: [Int]!) {
    lastMovements(clients: $clients) {
      client_id
      sensor_id
      title
      sensor_type
      installed_at
      microshare_timestamp
    }
  }
`;
