export default {
  TOKEN: 'stc-token',
  ORGID: 'stc-orgid',
  ORGANISATION: 'stc-org',
  FULLNAME: 'stc-fullname',
  STATE: 'stc-state',
  DOMICILIARY: 'dom',
  CARE_HOME: 'care-home',
  CLIENT: 'stc-client',
  USER: 'stc-user',
};
