export default {
  TEMPERATURE: 'temperature',
  DOOR: 'open/close',
  HUMIDITY: 'humidity',
  FLOOD: 'flood',
  BED: 'bed_occupancy',
  KITCHEN: 'kitchen',
  BATHROOM: 'bathroom',
  BEDROOM: 'bedroom',
  LIVING_ROOM: 'living room',
  PANIC: 'panic_button',
  ILLUMINANCE: 'illuminance',
  MOTION: 'motion',
  CHAIR: 'chair',
  TOILET: 'toilet',
  ALL: 'all',
};
