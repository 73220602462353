export const toggleSensorFilter = (list, id) => {
  // id -1 means toggle ALL
  if (id === -1) {
    return [];
  }

  const newList = [...list];
  const index = newList.findIndex(i => i === id);

  // If the id already exists then remove it
  if (index > -1) {
    if (index === 0) {
      return newList.slice(1);
    }
    return [...newList.slice(0, index), ...newList.slice(index + 1)];
  }
  // Else add it
  return [...newList, id];
};
