import gql from "graphql-tag";

export const sensorsQuery = gql`
  query sensors($clientId: Int) {
    sensors(clientId: $clientId) {
      id
      title
      installed_at
      sensor_type
    }
  }
`;

export const sensorsWithSignalQuery = gql`
  query sensors($clientId: Int) {
    sensorsWithSignal(clientId: $clientId) {
      id
      title
      installed_at
      sensor_type
      value
      units
      description
      microshare_timestamp
      offline
    }
  }
`;

export const sensorsWithDataQuery = gql`
  query sensorsWithData($clientId: Int, $start: String, $end: String) {
    sensors(clientId: $clientId) {
      id
      title
      installed_at
      sensor_type
      data(start: $start, end: $end) {
        on
        off
        value
        units
        description
      }
    }
    alerts: alertsForGraph(clientId: $clientId, from: $start, to: $end) {
      id
      name
      timeRaised
      status
      data {
        state
        time
      }
    }
  }
`;

export const motionOverviewQuery = gql`
  query motionDetails($clientId: Int, $start: String, $end: String) {
    motionOverview(clientId: $clientId, start: $start, end: $end) {
      sensor_id
      sensor_type
      location
      timestamp
    }
  }
`;

export const motionDetailsQuery = gql`
  query motionDetails($clientId: Int, $start: String, $end: String) {
    motionDetails(clientId: $clientId, start: $start, end: $end) {
      sensor_id
      sensor_type
      value
      location
      timestamp
    }
  }
`;
