// import decode from 'jwt-decode';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { STORAGE } from './constants';

// FIXME: This is a weak way to check for authenticated user
export const isAuthenticated = () => {
  const token = localStorage.getItem(STORAGE.TOKEN);
  return token ? true : false;
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) =>
      isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
