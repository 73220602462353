import COLOUR from "./colours";

export default {
  MAX_ZOOM: 80,
  SENSORS_POLLING_INTERVAL: 15000, // every 15 seconds
  DEFAULT_MARGINS: { top: 0, left: 0, bottom: 0, right: 0 },
  HEADER_HEIGHT: 48, // Timeline header in px
  SENSOR_LABEL_WIDTH: 220, // Width of sensor label in px
  ROW_HEIGHT: 32, // Timeline row height in px
  MIN_BAR_HEIGHT: 24, // Timeline bar height in px
  BAR_HEIGHT_PERCENT: 80, // bar height as a percentage of row height
  DAY_LABEL_WIDTH: 4, // Width of time label, used for ticks calc and right shift
  DAY_LABEL_VERTICAL_ADJUSTMENT: 20, // Drop text
  TIME_LABEL_WIDTH: 48, // Width of time label, used for ticks calc and right shift
  TIME_LABEL_VERTICAL_ADJUSTMENT: -4, // Lift text
  SENSOR_LABEL_VERTICAL_ADJUSTMENT: -22, // Lift text
  POINT_TOP_OFFSET: 0, // Distance from centre of row (+ve is down)
  POINT_SIZE: 8, // Variance (radius) of point diamonds
  LINE_BAR_TOLERANCE: 0.05, // Multiply data extent by this percentage for line chart padding
  MIN_TIME_BAR_RECTANGLE_WIDTH: 2, // Smallest width for a time bar slice

  // Graph types to render for given sensor type
  TYPE: {
    motion: "BAR",
    flood: "BAR",
    bed_occupancy: "BAR",
    chair: "BAR",
    "open/close": "BAR",
    panic_button: "POINT",
    temperature: "AREA",
    humidity: "AREA",
    illuminance: "AREA",
    ALERT: "ALERT",
  },

  // Colour sequence for ring and pie charts
  ALERTS_COLOUR_SEQUENCE: [
    "#d5374b",
    "#ffc107",
    "#698f38",
    COLOUR.PURPLE,
    "#001722",
    "#7D99AE",
    "#9399CB",
  ],

  // Colour sequence for ring and pie charts
  COLOUR_SEQUENCE: [
    "#97D0F1",
    "#31385A",
    "#2E75A5",
    COLOUR.PURPLE,
    "#001722",
    "#7D99AE",
    "#9399CB",
  ],
  // COLOUR_SEQUENCE: [
  //   COLOUR.PURPLE,
  //   COLOUR.YELLOW,
  //   COLOUR.PALE_BLUE,
  //   COLOUR.PALE_GREY
  // ]
  OPACITY: 0.7,

  MOTION_MIN_BAR_HEIGHT: 48,
  MOTION_HEADER_HEIGHT: 24,
  MOTION_SENSOR_LABEL_WIDTH: 120,
  MOTION_CHART_FIXED_WIDTH: 6048, // 252 timebox * 24
  MOTION_CHART_TIME_BLOCK_HEIGHT: 104,
  MOTION_TIME_AXIS_HEIGHT: 26,
  MOTION_LINE_BAR_TOLERANCE: 0.2,
};
