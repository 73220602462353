// Sort array of objects by any named key
export const sortByKey = (key, order = 'asc') => (a, b) => {
  const diff = order === 'asc' ? -1 : 1;
  if (a[key] < b[key]) return diff;
  if (a[key] > b[key]) return -diff;
  return 0;
};

export const addToArray = (array, item) => [...array, item];

export const addToStartOfArray = (array, item) => [item, ...array];

export const updateArray = (array, item, index = array.length - 1) => {
  if (index === -1) {
    return addToArray(array, item);
  }
  return [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1, array.length)
  ];
};

export const removeFromArray = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1, array.length)
];

export const findKeyInObject = (key, object) => {
  if (!key || !object || typeof object !== 'object') {
    return false;
  }
  let found = false;

  if (object.collection) {
    object.collection.forEach(o => {
      if (o.collection && !found) {
        found = findKeyInObject(key, o);
      } else {
        Object.entries(o).forEach(([k, v]) => {
          if (k === 'name' && v === key) {
            found = true;
          }
        });
      }
    });
  }
  return found;
};
