import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 48px;
`;

const Circle = styled.img`
  border: 2px solid white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  min-height: 48px;
  clip-path: circle(60px at center);
  background-color: white;
`;

const Avatar = ({ image }) => {
  return (
    <Container>
      <Circle src={image} />
    </Container>
  );
};

export default Avatar;
