import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { STORAGE } from './constants';
import { onError } from 'apollo-link-error';
import { forceLogout } from './actions';

const uri =
  process.env.REACT_APP_SERVER_API ||
  'https://graphqlapi.sweettreeconnect.com/graphql';
const wsUri =
  process.env.REACT_APP_SERVER_WS ||
  'wss://graphqlapi.sweettreeconnect.com/graphql';
// const uri = process.env.REACT_APP_SERVER_API;
// const wsUri = process.env.REACT_APP_SERVER_WS;

const httpLink = createHttpLink({ uri });

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem(STORAGE.TOKEN) || null,
    },
  });
  return forward(operation);
});

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // graphQLErrors.forEach(({ message, locations, path }) =>
    //   console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    // );
  }
  if (networkError) {
    console.log(`[Network error]: Message: ${networkError}`);
    const { name, result } = networkError;
    // If we have a ServerError with bad response from initialQuery
    // then the access token has expired or the server has restarted
    // and we need to logout the client.
    if (name === 'ServerError' && result && result.message === 'logout') {
      forceLogout();
    }
  }
});

const httpLinkWithMiddleware = logoutLink.concat(
  middlewareLink.concat(httpLink)
);

// Subscriptions setup
export const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      token: localStorage.getItem(STORAGE.TOKEN),
      // refreshToken: localStorage.getItem('refreshToken')
    }),
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLinkWithMiddleware
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
