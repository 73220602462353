import gql from "graphql-tag";

export const alertsQuery = gql`
  query alerts(
    $clientId: Int
    $from: String
    $to: String
    $offset: Int
    $limit: Int
  ) {
    alerts(
      clientId: $clientId
      from: $from
      to: $to
      offset: $offset
      limit: $limit
    ) {
      id
      name
      location
      clientId
      clientName
      photo
      timeRaised
      timeClosed
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
      status
    }
  }
`;

export const openAlertsQuery = gql`
  query openAlerts($clientId: Int, $offset: Int, $limit: Int) {
    openAlerts(clientId: $clientId, offset: $offset, limit: $limit) {
      id
      name
      location
      clientId
      timeRaised
      timeClosed
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
      status
    }
  }
`;

export const completedAlertsQuery = gql`
  query completedAlerts($clientId: Int, $offset: Int, $limit: Int) {
    completedAlerts(clientId: $clientId, offset: $offset, limit: $limit) {
      id
      name
      location
      clientId
      timeRaised
      timeClosed
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
      status
    }
  }
`;

export const orderedAlertsQuery = gql`
  query orderedAlerts($clientId: Int, $offset: Int, $limit: Int) {
    openAlerts(clientId: $clientId) {
      id
      name
      location
      clientId
      clientName
      photo
      timeRaised
      status
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
    }
    completedAlerts(clientId: $clientId, offset: $offset, limit: $limit) {
      id
      name
      location
      clientId
      clientName
      photo
      timeRaised
      timeClosed
      status
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
    }
  }
`;

export const alertsForGraph = gql`
  query alertsForGraph(
    $clientId: Int
    $from: String
    $to: String
    $offset: Int
    $limit: Int
  ) {
    alertsForGraph(
      clientId: $clientId
      from: $from
      to: $to
      offset: $offset
      limit: $limit
    ) {
      id
      name
      location
      clientId
      clientName
      photo
      timeRaised
      timeClosed
      status
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
      data {
        state
        time
      }
    }
  }
`;

// A minimal query to overlay an alerts bar at the top of data graph
export const alertsOverlay = gql`
  query alertsForGraph($clientId: Int, $from: String, $to: String) {
    alertsForGraph(clientId: $clientId, from: $from, to: $to) {
      id
      name
      timeRaised
      status
      data {
        state
        time
      }
    }
  }
`;
