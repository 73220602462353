import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import { setOrganisation } from '../../../actions';

// TODO: Remove Redux and replace with context

const mapStateToProps = ({ settings }) => ({ settings });

const mapDispatchToProps = (dispatch) => ({
  setOrganisation: () => dispatch(setOrganisation()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
