import gql from 'graphql-tag';

export const initialQuery = gql`
  query initialQuery {
    currentUser {
      id
      username
      profile {
        id
        first_name
        last_name
        sex
        photo
        related_dashboards
      }
      role {
        title
        default
        permissions {
          clients_permissions {
            full_access_for_retrieve
          }
          contacts_permissions {
            full_access_for_retrieve
          }
          sensors_permissions {
            full_access_for_retrieve
          }
          users_permissions {
            full_access_for_retrieve
          }
          dashboard_monitoring_permissions {
            full_access_for_retrieve
            related_objects_only
          }
        }
      }
    }
    monitoring {
      id
      client {
        id
        first_name
        last_name
        photo
        address
        dashboard
        sensors_count
      }
      sensors {
        id
        title
        sensor_type
        installed_at
      }
    }
  }
`;
