import { push } from 'react-router-redux';
import { STORAGE } from '../constants';
export const SET_STATUS = 'SET_STATUS';
export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
export const SET_SENSOR_FILTER = 'SET_SENSOR_FILTER';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_GRAPH_ZOOM = 'SET_GRAPH_ZOOM';
export const SET_ORGANISATION = 'SET_ORGANISATION';
export const LOGOUT = 'LOGOUT';

export const setStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS,
    payload,
  });
};

export const setSelectedClient = (payload) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CLIENT,
    payload,
  });
};

export const toggleSensorFilter = (payload) => (dispatch) => {
  dispatch({
    type: SET_SENSOR_FILTER,
    payload,
  });
};

export const setDateRange = (payload) => (dispatch) => {
  dispatch({
    type: SET_DATE_RANGE,
    payload,
  });
  dispatch(setGraphZoom({ x: 0, y: 0, k: 1 }));
};

export const setGraphZoom = (payload) => (dispatch) => {
  dispatch({
    type: SET_GRAPH_ZOOM,
    payload,
  });
};

export const setOrganisation = () => (dispatch) => {
  // Get organisation to determine 'domiciliary' mode (tenant id = 6)
  const orgid = localStorage.getItem(STORAGE.ORGID);
  const fullname = localStorage.getItem(STORAGE.FULLNAME);
  const org = localStorage.getItem(STORAGE.ORGANISATION);
  const orgType = orgid === '6' ? STORAGE.DOMICILIARY : STORAGE.CARE_HOME;
  const name =
    orgid === '6' ? 'SweetTree Home Care Services' : org || STORAGE.CARE_HOME;

  dispatch({
    type: SET_ORGANISATION,
    payload: {
      id: orgid,
      type: orgType,
      name,
      fullname,
    },
  });
};

export const login = (org) => (dispatch) => {
  // Update Redux
  const homePath = org.toString() === '6' ? '/clients' : '/';
  dispatch(setOrganisation());
  dispatch(push(homePath));
};

const removeStoredData = () => {
  localStorage.removeItem(STORAGE.TOKEN);
  localStorage.removeItem(STORAGE.ORGID);
  localStorage.removeItem(STORAGE.FULLNAME);
  localStorage.removeItem(STORAGE.ORGANISATION);
  localStorage.removeItem(STORAGE.CLIENT);
  localStorage.removeItem(STORAGE.USER);
};

export const logout = () => (dispatch) => {
  removeStoredData();
  // dispatch(push('/login'));
  window.location.href = '/login';
};

export const forceLogout = () => {
  removeStoredData();
  window.location.href = '/login';
};
