import { DEFAULT_CLIENT_ID, SENSOR_TYPE } from '../../constants';

export const getCurrentClientId = ({ clients, settings }) => {
  if (!clients || !Array.isArray(clients)) {
    return 0;
  }
  const { selectedClient } = settings;
  const clientIndex = selectedClient
    ? clients.findIndex((m) => m.client.id === selectedClient)
    : clients.findIndex((m) => m.client.id === DEFAULT_CLIENT_ID) || 0;
  const clientData = clients && clients[clientIndex];
  // Use client 0 if none found
  return clientData ? clientData.client.id : null;
};

// The old way - using Redux data shape
export const getCurrentSensors = ({ clients, settings }) => {
  if (!clients || !Array.isArray(clients)) {
    return null;
  }
  const { selectedClient } = settings;
  const clientIndex = selectedClient
    ? clients.findIndex((m) => m.client.id === selectedClient)
    : clients.findIndex((m) => m.client.id === DEFAULT_CLIENT_ID) || 0;
  const clientData = clients && clients[clientIndex];
  // Use client 0 if none found
  return clientData ? clientData.sensors : null;
};

export const getSensors = ({ client, clientList }) => {
  if (!client || !clientList || !Array.isArray(clientList)) {
    return null;
  }
  const selectedClient = client.id;
  return clientList
    .filter((c) => c.client.id === selectedClient)
    .flatMap((client) => client.sensors);
};

export const getNonMotionSensors = ({ client, clientList }) => {
  const sensors = getSensors({ client, clientList });
  if (!sensors) {
    return [];
  }
  return sensors
    .filter(
      (s) =>
        s.sensor_type !== SENSOR_TYPE.MOTION &&
        s.sensor_type !== SENSOR_TYPE.BED &&
        s.sensor_type !== SENSOR_TYPE.CHAIR &&
        s.sensor_type !== SENSOR_TYPE.PANIC
    )
    .map((ss) => ss.id);
};
