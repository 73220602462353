import React, { useState, useEffect, useContext, memo } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { mdiMenu } from '@mdi/js';
import { BoxIcon } from '../../common/Icons';
import SideNav from '../SideNav';
import OffCanvas from 'react-aria-offcanvas';
import { Dropdown } from 'semantic-ui-react';
import { IconAccount } from '../../common/Icons';
import Avatar from '../Avatar';
import { COLOUR, STORAGE } from '../../../constants';
import { isAuthenticated } from '../../../routePermissions';
import { ClientContext } from '../../../context/ClientContext';
import { makeName } from '../../common/utils';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${COLOUR.PURPLE};
  fill: white;
  min-height: 48px;
  align-items: center;
  text-align: left;
  padding: 0 8px;
  .icon {
    margin-right: 8px;
  }
  a {
    color: white;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Left = styled(HeaderContent)`
  text-align: left;
  width: 200px;
`;

const Centre = styled(HeaderContent)`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Right = styled(HeaderContent)`
  justify-content: flex-end;
  padding-right: 8px;
  width: 200px;
`;

const HomeLink = styled(Link)`
  line-height: 30px;
`;

// Adjust position of text for vertical center with icon
const MenuText = styled.div`
  padding: 12px 8px 16px 0;
  color: white;
  text-transform: uppercase;
`;

const Close = styled(HeaderContent)`
  text-align: left;
  & :hover {
    cursor: pointer;
  }
`;

const ClientContainer = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Name = styled.div`
  color: ${COLOUR.LIGHT};
  font-size: 18px;
`;

const Header = ({ settings: { organisation }, setOrganisation }) => {
  const [open, setOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  // Selected client context
  const { client, setClient } = useContext(ClientContext);
  const firstName = client && client.firstName;
  const lastName = client && client.lastName;
  const sex = client && client.sex;
  const photo = client && client.photo;
  const address = client && client.address;
  const clientName = makeName(sex, firstName, lastName, address);
  const hasPhoto = !!photo;
  const auth = isAuthenticated();

  // Set the correct organisation type from organisationName setting
  useEffect(() => {
    setOrganisation();
  }, [setOrganisation]);

  if (!organisation) {
    return <Redirect to="/login" push />;
  }

  if (logout) {
    return <Redirect to="/logout" push />;
  }

  const { name, fullname, type } = organisation;
  // Home icon links to clients for dom care and dashboard for care homes
  const homeLink = type === STORAGE.DOMICILIARY ? '/clients' : '/';

  const trigger = (
    <UserMenu>
      <MenuText>{fullname}</MenuText>
      <IconAccount />
    </UserMenu>
  );

  const menuOptions = [
    { key: 'logout', text: 'Logout', onClick: () => setLogout(true) },
  ];

  return (
    <HeaderContainer>
      <Left>
        {auth && (
          <Close onClick={() => setOpen(true)}>
            <BoxIcon path={mdiMenu} title="Menu" size={1} />
            {/* <MenuText>MENU</MenuText> */}
          </Close>
        )}
        <HomeLink to={homeLink} onClick={() => setClient(null)}>
          HOME
        </HomeLink>
      </Left>
      <Centre>
        <ClientContainer to="/client">
          {hasPhoto && <Avatar image={photo} />}
          <Name>{clientName}</Name>
        </ClientContainer>
      </Centre>
      <Right>
        {auth && (
          <Dropdown
            trigger={trigger}
            header={name}
            options={menuOptions}
            icon={null}
            direction="left"
          />
        )}
      </Right>

      <OffCanvas
        height="100%"
        isOpen={open}
        onClose={() => setOpen(false)}
        labelledby="menu-button"
        closeOnOverlayClick
        closeOnEsc
      >
        <SideNav closeHandler={() => setOpen(false)} />
      </OffCanvas>
    </HeaderContainer>
  );
};

// Header.propTypes = {
//   settings: PropTypes.object.isRequired,
// };

export default memo(Header);
