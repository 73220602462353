import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import settings from './Settings';
import graph from './Graph';

export default combineReducers({
  settings,
  graph,
  router: routerReducer
});
