import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { SENSOR_TYPE } from "../../constants";

dayjs.extend(relativeTime);

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

// Create the relative time text message from timestamp
export const getTime = (time) => {
  if (!time) {
    return null;
  }
  const timeValue = parseInt(time, 10);
  const t = dayjs(timeValue);
  const daysAgo = (dayjs() - t) / DAY_IN_MILLISECONDS;

  // TODO need to make sure 24 hour periods include 'yesterday'
  // Show date if > 24 hours ago  "23 Jul 2019 - 12:18"
  if (daysAgo > 1) {
    return t.format("DD MMM YYYY - HH:mm");
  }

  const timestamp = t.format("HH:mm");

  const ago = dayjs().to(t).replace("minute", "min");

  return `${timestamp} (${ago})`;
};

const round = (value) => {
  const numValue = parseInt(value, 10);
  return isNaN(numValue) ? value : Math.round(numValue);
};

export const getStatus = ({ type, value, units, offline }) => {
  if (type === SENSOR_TYPE.MOTION) {
    return value ? "Movement detected" : "No movement";
  }
  if (type === SENSOR_TYPE.DOOR) {
    return value === "true" ? "Open" : "Closed";
  }
  if (type === SENSOR_TYPE.FLOOD) {
    return value === "true" ? "Last leak" : "No leak since";
  }
  if (type === SENSOR_TYPE.PANIC) {
    return "Last pressed";
  }
  return `${round(value)} ${units}`;
};

export const sentenceCase = (word) => {
  if (!word) {
    return null;
  }
  if (typeof word !== "string") {
    return word;
  }

  return (
    word.charAt(0).toUpperCase() + word.slice(1, word.length).toLowerCase()
  );
};

export const makeName = (sex, first, last, address) => {
  let title = "";
  if (sex === "male") {
    title = "Mr.";
  }
  if (sex === "female") {
    title = "Mrs.";
  }

  // const name = [title, first, last].map((word) => sentenceCase(word)).join(' '); // Write sentence case name
  const name = [title, first, last].join(" ");
  if (address) {
    return `${name} - ${address}`;
  }
  return name;
};
