import gql from 'graphql-tag';

export const createAlertInstanceMutation = gql`
  mutation createAlertInstance(
    $type: String!
    $clientId: Int!
    $tenantId: Int!
    $config: String!
    $location: String
  ) {
    createAlertInstance(
      type: $type
      clientId: $clientId
      tenantId: $tenantId
      config: $config
      location: $location
    ) {
      success
      id
    }
  }
`;

export const updateAlertInstanceMutation = gql`
  mutation updateAlertInstance(
    $id: Int!
    $type: String!
    $clientId: Int!
    $tenantId: Int!
    $config: String!
    $location: String
  ) {
    updateAlertInstance(
      id: $id
      type: $type
      clientId: $clientId
      tenantId: $tenantId
      config: $config
      location: $location
    ) {
      success
    }
  }
`;

export const updateAlertActiveMutation = gql`
  mutation updateAlertActive($id: Int!, $active: Boolean!) {
    updateAlertActive(id: $id, active: $active) {
      success
    }
  }
`;

export const deleteAlertMutation = gql`
  mutation deleteAlert($id: Int!) {
    deleteAlert(id: $id) {
      success
    }
  }
`;
