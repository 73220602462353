import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOUR } from '../../../constants';

const Container = styled(Link)`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0px 16px 0px 24px;
  transation: all 0.2s;
  color: ${COLOUR.PALE_GREY} !important;
  background-color: ${(props) =>
    props.active ? `${COLOUR.MENU_ACTIVE_DARK}` : `${COLOUR.MENU_DARK}`};
  font-size: 16px;
  &:hover {
    background-color: ${COLOUR.BLUE};
    color: #fff !important;
    cursor: pointer;
  }
`;

const SideNavLink = ({ text, link, title, active, closeHandler }) => {
  const titleText = title || `Go to ${text} page`;
  const isActive = active ? 1 : 0; // Needed for styled components props

  return (
    <Container
      to={link}
      title={titleText}
      active={isActive}
      onClick={closeHandler}
    >
      {text}
    </Container>
  );
};

SideNavLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  active: PropTypes.bool,
};

export default SideNavLink;
