import COLOUR from './colours';
import CONNECTORS from './connectors';
import SENSOR_TYPE from './sensorType';
import LOCATIONS from './locations';
import GRAPH from './graph';
import TIME, { NIGHT_END, NIGHT_START } from './time';
import ALERT_ACTIONS from './alertActions';
import ALERT_STATUS from './alertStatus';
import STORAGE from './localStorage';

export {
  COLOUR,
  CONNECTORS,
  SENSOR_TYPE,
  LOCATIONS,
  GRAPH,
  TIME,
  NIGHT_START,
  NIGHT_END,
  ALERT_ACTIONS,
  ALERT_STATUS,
  STORAGE,
};

export const REPORT_NAME = 'Alerts';
export const TOOLTIP_WIDTH = 220;
export const DEFAULT_CLIENT_ID = 76; // Used for demo
