import gql from 'graphql-tag';

export const createAlertMutation = gql`
  mutation createAlert($typeId: Int!, $location: String, $clientId: Int) {
    createAlert(typeId: $typeId, location: $location, clientId: $clientId)
  }
`;

export const takeAlertMutation = gql`
  mutation takeAlert($alertId: Int!, $ownerId: Int!) {
    takeAlert(alertId: $alertId, ownerId: $ownerId)
  }
`;

export const untakeAlertMutation = gql`
  mutation untakeAlert($alertId: Int!) {
    untakeAlert(alertId: $alertId)
  }
`;

export const completeAlertMutation = gql`
  mutation completeAlert($alertId: Int!) {
    completeAlert(alertId: $alertId)
  }
`;
