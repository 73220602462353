import { createBrowserHistory } from "history";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import client from "./apollo";
import Routes from "./routes";
import configureStore from "./store";
import { toast } from "react-toastify";
import { version } from "../package.json";
import UserContextProvider from "./context/UserContext";
import ClientContextProvider from "./context/ClientContext";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

console.info(`Version ${version}`);

toast.configure({ autoClose: 5000 });
// import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <UserContextProvider>
          <ClientContextProvider>
            <Routes />
          </ClientContextProvider>
        </UserContextProvider>
      </Provider>
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);
