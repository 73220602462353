import React from 'react';
import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

// Layout
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 32px 16px;
  margin-bottom: 48px;
`;

export const Inner = styled.div`
  max-width: 960px;
  margin: auto;
`;

export const Section = styled.section`
  font-size: 18px;
  margin-bottom: 16px;
`;

const List = styled.li`
  font-size: 18px;
  line-height: 24px;
`;

const PrivacyPolicy = () => (
  <Container>
    <Inner>
      <h1>Privacy Policy</h1>
      <article>
        <Section>
          <p>
            SweetTree Connect Limited is committed to protecting the privacy and
            security of your personal data. This notice describes how we collect
            and process personal data about you while providing you services (“
            <strong>Privacy Notice</strong>”).
          </p>
          <p>
            This Privacy Notice describes the categories of personal data that
            we collect, how we use your personal data, how we secure your
            personal data, when we may disclose your personal data to third
            parties, and when we may transfer your personal data outside of your
            home jurisdiction. This Privacy Notice also describes your rights
            regarding the personal data that we hold about you including how you
            can access, correct, and request erasure of your personal data.
          </p>
        </Section>
        <Section>
          <h2>Collection of Personal Data</h2>
          <p>
            For purposes of this Privacy Notice, personal data means any
            information about an identifiable individual. We hold the personal
            data that you have supplied us so we may carry out our activities
            inorder to be able to provide the service you have subscribed to. We
            may collect, store, and process the following categories of personal
            data, for the purpose of administering the services to you:
          </p>
          <ul>
            <List>
              Personal contact details such as name, title, addresses, telephone
              numbers, and personal email addresses.
            </List>
            <List>Date of birth.</List>
            <List>Gender.</List>
            <List>Marital and dependent status.</List>
            <List>Beneficiary and emergency contact information.</List>
            <List>Next of kin.</List>
            <List>
              Any otherrelevant information that is required to be able to
              deliver the service e.g. financial information, other healthcare
              provider details.
            </List>
          </ul>
        </Section>
        <Section>
          <h2>Use of Personal Data</h2>
          <p>
            We only process your personal data where applicable law permits or
            requires it, including where the processing is necessary for the
            performance of our service contract with you, where the processing
            is necessary to comply with a legal obligation that applies to us,
            for our legitimate interests or the legitimate interests of third
            parties, to protect your vital interests, or with your consent if
            applicable law requires consent. We may process your personal data
            for the following legitimate business purposes and for the purposes
            of performing the services for you, summarised below:
          </p>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.HeaderCell>Intended use</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <tbody>
              <Table.Row>
                <Table.Cell>Personal contact details</Table.Cell>
                <Table.Cell>
                  To issue invoices, keep a record of invoices raised vs paid,
                  which is required for audit purposes.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Beneficiary, emergency contact,next of kin information
                </Table.Cell>
                <Table.Cell>
                  <p>
                    For notification if circumstances arise where this is
                    required.
                  </p>
                  <p>
                    To provide access to monitoring platform if authorised to do
                    so.
                  </p>
                  <p>Communications relating to service being delivered.</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Date of Birth / Gender</Table.Cell>
                <Table.Cell>Anonymised reporting internally.</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  All data held depending on nature of reporting requirement
                </Table.Cell>
                <Table.Cell>
                  <p>
                    To comply with applicable law -Data Protection request for
                    information from the ICO.
                  </p>
                  <p>To comply with health and safety obligations.</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>External Healthcare providers</Table.Cell>
                <Table.Cell>
                  To provide access to monitoring platform if authorised to do
                  so.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Care preferences/ photos </Table.Cell>
                <Table.Cell>
                  To enable staff to deliver the services we provide.
                </Table.Cell>
              </Table.Row>
            </tbody>
          </Table>

          <p>
            We will only process your personal data for the purposes we
            collected it for or for compatible purposes. If we need to process
            your personal data for an incompatible purpose, we will provide
            notice to you and, if required by law, seek your consent. We may
            process your personal data without your knowledge or consent only
            where required by applicable law or regulation.
          </p>
          <p>
            You will not be subject to decisions based on automated data
            processing without your prior consent.
          </p>
        </Section>
        <Section>
          <h2>Collection and Use of Special Categories of Personal Data</h2>
          <p>
            The following special categories of personal data are considered
            sensitive: Racial or ethnic origin; Political opinions; Religious or
            philosophical beliefs; Trade union membership; Biometric data; Data
            concerning health; Genetic data, Data concerning sex life or sexual
            orientation; and Data relating to criminal convictions and offences.
          </p>
          <p>
            We may collect and process the special categories of personal data
            when you voluntarily provide them,for our legitimate business
            purposes in delivering the service, to carry out our obligations as
            required by law.
          </p>
          <p>
            Where we have a legitimate need to process special categories of
            personal data about you for purposes not identified above, we will
            only do so only after providing you with notice and, if required by
            law, obtaining your prior, express consent.
          </p>
        </Section>
        <Section>
          <h2>Data Sharing</h2>
          <p>
            We will only disclose your personal data to third parties where
            required by law or to our employees, contractors, designated agents,
            or third-party service providers who require such information to
            assist us with administering our services to you, including
            third-party service providers who provide services to us or on our
            behalf. Examples of third-party service providers are other
            healthcare providers, regulators, safeguarding authorities,
            professional advisors and technology providers.
          </p>
          <p>
            We may also disclose your personal data for the following additional
            purposes where permitted or required by applicable law:
          </p>
          <ul>
            <List>
              To comply with legal obligations or valid legal processes such as
              search warrants, subpoenas, or court orders. When we disclose your
              personal data to comply with a legal obligation or legal process,
              we will take reasonable steps to ensure that we only disclose the
              minimum personal data necessary for the specific purpose and
              circumstances.
            </List>
            <List>
              During emergency situations or where necessary to protect the
              safety of persons.
            </List>
            <List>Where the personal data is publicly available.</List>
            <List>
              If a business transfer or change in ownership occurs and the
              disclosure is necessary to complete the transaction. In these
              circumstances, we will limit data sharing to what is absolutely
              necessary and we will anonymizethe data where possible.
            </List>
            <List>
              For additional purposes with your consent where such consent is
              required by law.
            </List>
          </ul>
        </Section>
        <Section>
          <h2>Data Analysis</h2>
          <p>
            We collect data as part of our service which is used to analyse and
            identify certain aspects of an individual’s behaviour, track
            movements,health profile which is then used to generate reports. We
            endeavour to ensure that our analyses does not introduce bias based
            on discriminatory factors like race, age, religious beliefs, gender,
            socioeconomic situation.
          </p>
        </Section>
        <Section>
          <h2>Data Security</h2>
          <p>
            We have implemented appropriate physical, technical, and
            organizational security measures designed to secure your personal
            data against accidental loss and unauthorized access, use,
            alteration, or disclosure. In addition, we limit access to personal
            data to those employees, agents, contractors, and other third
            parties that have a legitimate business need for such access.
          </p>
        </Section>
        <Section>
          <h2>Data Retention</h2>
          <p>
            Except as otherwise permitted or required by applicable law or
            regulation, we will only retain your personal data for as long as
            necessary to fulfil the purposes we collected it for, as required to
            satisfy any legal, accounting, or reporting obligations, or as
            necessary to resolve disputes.
          </p>
        </Section>
        <Section>
          <h2>Rights of Access, Correction, Erasure, and Objection</h2>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes.
            By law you may havethe right to request access to, correct, and
            erase the personal data that we hold about you, or object to/
            restrictthe processing of your personal data under certain
            circumstances. You may also have the right to request that we
            transfer your personal data to another party. If you want to review,
            verify, correct, or request erasure of your personal data, object to
            the processing of your personal data, or request that we transfer a
            copy of your personal data to another party, please contact{' '}
            <a
              href="mailto:support@sweettreeconnect.com"
              title="Contact email address"
            >
              support@sweettreeconnect.com
            </a>
            .
          </p>
          <p>
            Applicable law may allow or require us to refuse to provide you with
            access to some or all of the personal data that we hold about you,
            or we may have destroyed, erased, or made your personal data
            anonymous in accordance with our record retention obligations and
            practices. If we cannot provide you with access to your personal
            data, we will inform you of the reasons why, subject to any legal or
            regulatory restrictions.
          </p>
        </Section>
        <Section>
          <h2>Changes To This Privacy Policy</h2>
          <p>
            We reserve the right to update this Privacy Notice at any time. If
            we would like to use your previously collected personal data for
            different purposes than those we notified you about at the time of
            collection, we will provide you with notice and, where required by
            law, seek your consent, before using your personal data for a new or
            unrelated purpose. We may process your personal data without your
            knowledge or consent only where required by applicable law or
            regulation.
          </p>
        </Section>
        <Section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about our processing of your personal data
            or would like to make an access or other request, please contact{' '}
            <a
              href="mailto:support@sweettreeconnect.com"
              title="Contact email address"
            >
              support@sweettreeconnect.com
            </a>
            .
          </p>
          <p>
            If you are unsatisfied with our response to any issues that you
            raise, you have the right to make a complaint with the Information
            Commissioner’s Office. The ICO can be contacted on 0303 123 1113{' '}
            <a
              href="https://www.ico.org.uk"
              title="Contact ICO"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.ico.org.uk
            </a>
            .
          </p>
        </Section>
      </article>
    </Inner>
  </Container>
);

export default PrivacyPolicy;
