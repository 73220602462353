import { SENSOR_TYPE, ALERT_STATUS } from '../../constants';
import { formatStandardDateTime } from './dates';

// Convert server sensor data into a local format
/*
  "description": "True = presence detected",
  "id": 915,
  "installed_at": "Office",
  "microshare_timestamp": "1568801160937",
  "sensor_type": "motion",
  "title": "Motion 1 (005C)",
  "units": null,
  "value": "true",

  ===>

  installed_at -> location
  microshare_timestamp => timestamp
  sensor_type -> type
*/
export const convertSensorData = receivedData => {
  const {
    installed_at,
    microshare_timestamp,
    sensor_type,
    ...data
  } = receivedData;

  return {
    location: installed_at,
    timestamp: microshare_timestamp,
    type: sensor_type,
    ...data
  };
};

// Convert sensor type, state and value into presented value
export const getSensorValue = ({ type, value, units }) => {
  if (type === SENSOR_TYPE.TEMPERATURE || type === SENSOR_TYPE.HUMIDITY) {
    return `${value} ${units}`;
  }

  if (type === SENSOR_TYPE.DOOR) {
    return value === 'true' ? 'Open' : 'Closed';
  }

  if (type === SENSOR_TYPE.MOTION) {
    return 'Last Motion';
  }

  return null;
};

/*
actions: [{…}]
clientId: 1
clientName: "Rob Test"
id: 9
location: "Room 1"
name: "Panic Button Pressed"
photo: "https://s3.eu-west-2.amazonaws.com/stc-develop/media/photos/fdb7410bbba0839dd451f1ae06980edbfdfd3172"
status: "Completed"
timeClosed: "1569404889320"
timeRaised: "1569404111391"
*/

const transformStatus = status => {
  if (status === ALERT_STATUS.OPEN) {
    return 'New';
  }
  if (status === ALERT_STATUS.RESPONDING) {
    return 'In progress';
  }
  return 'Complete';
};

export const transformAlerts = alerts =>
  alerts.map(a => ({
    ...a,
    timeRaisedText: formatStandardDateTime(a.timeRaised),
    status: transformStatus(a.status),
    staff: a.actions && a.actions[0] && a.actions[0].ownerName
  }));

export const filterAlerts = (alerts, filters) => {
  let filtered = [...alerts];
  const iterableFilters = Object.entries(filters);

  iterableFilters.forEach(([type, { text, value }]) => {
    if (value > 0) {
      filtered = filtered.filter(f => f[type] === text);
    }
  });

  return filtered;
};
