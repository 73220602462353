const COLOUR = {
  BLUE: "#0186B1",
  PALE_BLUE: "#C4D2E1",
  PURPLE: "#9D0A6D",
  DARK_PURPLE: "#7E0958",
  RED: "#DB5062",
  DARK_RED: "#d5374b",
  PINK: "#E17381",
  ORANGE: "#F08D58",
  YELLOW: "#FFC107",
  GREEN: "#698f38",
  DARK_GREEN: "#5e8032",
  BLACK: "#2b2b2b",
  GREY: "#666",
  MID_GREY: "#828282",
  PALE_GREY: "#E5E5E5",
  LIGHT: "#f6f6f6",
  MENU_DARK: "rgb(36, 36, 36)",
  MENU_ACTIVE_DARK: "rgb(48, 48, 48)",
  WHITE: "#fff",
  BED_BLUE: "#0D47A1",

  MATERIAL_LIGHT_BLUE: "#81D4FA",
  MATERIAL_BLUE: "#0288D1",
  MATERIAL_CYAN: "#80DEEA",
  MATERIAL_TEAL: "#80CBC4",
  MATERIAL_GREEN: "#A5D6A7",
  MATERIAL_ORANGE: "#FFCC80",
  MATERIAL_DEEP_ORANGE: "#FFAB91",
  MATERIAL_BROWN: "#BCAAA4",
  MATERIAL_OUT_OF_BED: "#FFCDD2",

  MOTION: {
    default: "#c2d2e1",
    bedroom: "#64B5F6",
    bathroom: "#F9A825",
    chair: "5e8032",
    bed_occupancy: "rgb(0, 200, 83)",
    temperature: "rgb(48, 48, 48)",
    humidity: "rgb(48, 48, 48)",
    illuminance: "#F9A825",
    toilet: "#A5D6A7",
    door: "#8c8d8f",
    button: "rgb(48, 48, 48)",
    flood: "rgb(48, 48, 48)",
  },
};

// Set component-specific colours from reference set above
COLOUR.BAR_COLOUR = COLOUR.BLUE; // Bar chart colour

export default COLOUR;
