import React, { Suspense, lazy, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ClientContext } from './context/ClientContext';
import { Header, TopNav, PrivacyPolicy } from './components';
import Loader from './components/common/Loader';
import { PrivateRoute } from './routePermissions';
import { STORAGE } from './constants';
import './index.css';

const AppContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: 0;
  overflow-y: auto;
`;

// Lazy imports allow js modules to be chunked by webpack
const LazyLogin = lazy(() => import('./components/Login'));
const LazyLogout = lazy(() => import('./components/Logout'));
const LazyForgotten = lazy(() => import('./components/Forgotten'));
const LazyClientView = lazy(() => import('./components/ClientView'));
const LazyClients = lazy(() => import('./components/Clients'));
const LazyGraphView = lazy(() => import('./components/GraphView'));
const LazyDashboard = lazy(() => import('./components/Dashboard'));
const LazyAlertsView = lazy(() => import('./components/AlertsView'));
const LazyAlertsFormView = lazy(() => import('./components/AlertsForm'));
const LazyMovementDetailView = lazy(() =>
  import('./components/MovementDetailView')
);
const LazyMovementWeekView = lazy(() =>
  import('./components/MovementWeekView')
);
const LazyRoomLayoutView = lazy(() => import('./components/RoomLayoutView'));
const LazyResidentAlertSummary = lazy(() =>
  import('./components/ResidentAlertSummary')
);

const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route path="/privacy" exact component={PrivacyPolicy} />

      <PrivateRoute
        path="/"
        exact
        component={() => {
          const isCareHome = localStorage.getItem(STORAGE.ORGANISATION) !== '6';
          if (isCareHome) return <LazyDashboard />;
          return <LazyClients />;
        }}
      />
      <PrivateRoute path="/client" exact component={LazyClientView} />
      <PrivateRoute path="/clients" exact component={LazyClients} />
      <PrivateRoute path="/graph/:sensorId" exact component={LazyGraphView} />
      <PrivateRoute path="/graph" exact component={LazyGraphView} />
      <PrivateRoute
        path="/resident-alerts"
        exact
        component={LazyResidentAlertSummary}
      />
      <PrivateRoute
        path="/movement-overview"
        exact
        component={LazyMovementWeekView}
      />
      <PrivateRoute
        path="/movement-details/:date"
        exact
        component={LazyMovementDetailView}
      />
      <PrivateRoute path="/room" exact component={LazyRoomLayoutView} />
      <PrivateRoute path="/alerts" exact component={LazyAlertsView} />
      <PrivateRoute
        path="/alerts/create/:type"
        exact
        component={LazyAlertsFormView}
      />
      <PrivateRoute path="/alerts/:id" exact component={LazyAlertsFormView} />
    </Switch>
  </Suspense>
);

/**
 * Login, logout and forgotten routes do not have a header in their layout
 */
const App = () => {
  const { client } = useContext(ClientContext);

  return (
    <AppContainer>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/login" exact component={LazyLogin} />
          <Route path="/logout" exact component={LazyLogout} />
          <Route path="/forgotten" exact component={LazyForgotten} />
          <>
            <Header />
            {client && <TopNav />}
            <ContentWrapper>
              <Routes />
            </ContentWrapper>
          </>
        </Switch>
      </Suspense>
    </AppContainer>
  );
};

export default withRouter(App);
