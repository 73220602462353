import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

// Create the relative time text message from timestamp
export const formatTimeAgo = date => dayjs().to(dayjs(date));

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

// Convert epoch time into date, time and 'ago'
const formatTime = time => {
  if (!time) {
    return null;
  }
  const timeValue = parseInt(time, 10);
  const t = dayjs(timeValue);
  const daysAgo = (dayjs() - t) / DAY_IN_MILLISECONDS;

  // Show date if > 24 hours ago  "23 Jul 2019 - 12:18"
  if (daysAgo > 1) {
    return t.format('DD MMM YYYY - HH:mm');
  }

  const timestamp = t.format('HH:mm');

  const ago = dayjs()
    .to(t)
    .replace('minute', 'min')
    .replace('second', 'sec');

  return `${timestamp} (${ago})`;
};

export const formatSensorTime = time => {
  if (!time) {
    return null;
  }
  // Establish whether date is ISO string or epoch
  if (typeof time === 'string' && time.indexOf('-') > 0) {
    const t = new Date(time).getTime();
    return formatTime(t);
  }
  return formatTime(time);
};

export const formatShortTimeAgo = date => {
  const timeValue = parseInt(date, 10);
  if (isNaN(timeValue)) {
    return null;
  }
  const long = dayjs().to(dayjs(timeValue));

  return long
    .replace(' ago', '')
    .replace(' years', 'y')
    .replace(' year', 'y')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace('a few', '~')
    .replace('an', '1')
    .replace('a', '1');
};

export const formatStandardDateTime = date => {
  if (!date) {
    return null;
  }
  return dayjs(parseInt(date, 10)).format('DD/MM/YYYY HH:mm');
};

// Sort array of objects by any named key
export const sortByKey = (key, order = 'asc') => (a, b) => {
  const diff = order === 'asc' ? -1 : 1;
  if (a[key] < b[key]) return diff;
  if (a[key] > b[key]) return -diff;
  return 0;
};

const getMonday = date => {
  var day = date.getDay() || 7;
  // Subtract days
  if (day !== 1) date.setHours(-24 * (day - 1));
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

const getStartOfMonth = date => {
  var day = date.getDate();
  if (day !== 1) date.setDate(1);
  return date.toISOString();
};

// This function is mirrored in the API,
const getTimeRange = daysAgo => {
  const d = new Date();
  // Set end to end of next hour
  const thisHour = d.getHours();
  d.setHours(thisHour + 1, 0, 0, 0);
  const end = d.toISOString();
  // Set start to 24 hours ago
  const start = dayjs(d)
    .subtract(daysAgo, 'day')
    .toISOString();

  return {
    start,
    end
  };
};

export const getLastDay = () => getTimeRange(1);

export const getLastWeek = () => getTimeRange(7);

export const getLastMonth = () => getTimeRange(30);

export const getWeekToDate = () => {
  const d = new Date();
  // Set end to end of next hour
  const thisHour = d.getHours();
  d.setHours(thisHour + 1, 0, 0, 0);
  const end = d.toISOString();

  return {
    start: getMonday(d),
    end
  };
};

export const getMonthToDate = () => {
  const d = new Date();
  // Set end to end of next hour
  const thisHour = d.getHours();
  d.setHours(thisHour + 1, 0, 0, 0);
  const end = d.toISOString();

  return {
    start: getStartOfMonth(d),
    end
  };
};
