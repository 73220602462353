import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { COLOUR } from '../../../constants';
import { ClientContext } from '../../../context/ClientContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background-color: ${COLOUR.DARK_PURPLE};
  height: 48px;
  min-height: 48px;
`;

const RightLinksContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;

const StyledLink = styled(Link)`
  color: white;
  padding: 16px;
  height: 100%;
  text-transform: uppercase;
  &:hover {
    color: white;
    background-color: #71084f;
  }

  &.active {
    background-color: #71084f;
  }
`;

const NavLink = ({ path, title, location }) => {
  // Show active with dark background
  const linkClass = location.pathname === path ? 'active' : '';

  return (
    <StyledLink to={path} className={linkClass}>
      {title}
    </StyledLink>
  );
};

const TopNav = ({ location }) => {
  const { client } = useContext(ClientContext);

  return (
    <Container>
      {client && (
        <RightLinksContainer>
          <NavLink path="/client" title="Overview" location={location} />
          <NavLink path="/graph" title="Graph" location={location} />
          <NavLink
            path="/movement-overview"
            title="Movement"
            location={location}
          />
          <NavLink path="/alerts" title="Alerts" location={location} />
        </RightLinksContainer>
      )}
    </Container>
  );
};

export default withRouter(TopNav);
