export const properCase = text => {
  const parts = text
    .trim()
    .toLowerCase()
    .replace('_', ' ')
    .split(' ');
  return parts
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};
