import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { COLOUR } from '../../constants';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
`;

// Alternative types include 'Bars' and 'Grid'
const Loading = () => (
  <Container>
    <Loader type="Oval" color={COLOUR.DARK_PURPLE} height={80} width={80} />
  </Container>
);

export const LoadingBars = () => (
  <Container>
    <Loader type="Bars" color={COLOUR.DARK_PURPLE} height={14} width={14} />
  </Container>
);

export default Loading;
