import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideNav from './SideNav';
import { toggleSensorFilter } from '../../../actions';

const mapStateToProps = ({ settings }) => ({ settings });

const mapDispatchToProps = (dispatch) => ({
  resetSensorFilter: () => dispatch(toggleSensorFilter(-1)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideNav)
);
