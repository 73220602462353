import gql from "graphql-tag";
import { addToStartOfArray, updateArray } from "../services/utils";

// TODO watching the screen, if you leave it and do not refresh, this takes over and seems to bring back correct information that the full refresh misses
export const alertUpdateSubscription = gql`
  subscription {
    alertUpdate {
      id
      name
      location
      clientId
      clientName
      photo
      timeRaised
      timeClosed
      actions {
        ownerId
        ownerName
        timeStarted
        timeEnded
        status
      }
      status
    }
  }
`;

export const mergeData = (prev, subscriptionData) => {
  if (!subscriptionData || !subscriptionData.alertUpdate) {
    return prev;
  }
  const alerts = prev && prev.alerts;

  if (!alerts) {
    return prev;
  }

  // Merge updated (or new) alert
  const { alertUpdate } = subscriptionData;
  const index = alerts.findIndex((a) => a.id === alertUpdate.id);
  let updatedAlerts = alerts || [];

  // If already in list then update else add
  if (index > -1) {
    updatedAlerts = updateArray(alerts, alertUpdate, index);
  } else {
    updatedAlerts = addToStartOfArray(updatedAlerts, alertUpdate);
  }

  return {
    alerts: updatedAlerts,
  };
};

export const alertsSubscriptionHandler = {
  document: alertUpdateSubscription,
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) {
      return prev;
    }
    const alerts = prev && prev.alerts;

    if (!alerts) {
      return prev;
    }

    // Merge updated (or new) alert
    const { alertUpdate } = subscriptionData.data;
    // Fix date format to epoch for timeRaised key
    if (alertUpdate) {
      alertUpdate.timeRaised = new Date(alertUpdate.timeRaised).getTime();
    }

    const index = alerts.findIndex((a) => a.id === alertUpdate.id);
    let updatedAlerts = alerts || [];

    // Already in list?
    if (index > -1) {
      // Update
      updatedAlerts = updateArray(alerts, alertUpdate, index);
    } else {
      // Add to open list
      updatedAlerts = addToStartOfArray(updatedAlerts, alertUpdate);
    }

    return {
      alerts: updatedAlerts,
    };
  },
};
