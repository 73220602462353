import { SET_GRAPH_ZOOM } from '../actions';

const initialState = {
  zoom: { x: 0, y: 0, k: 1 }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GRAPH_ZOOM: {
      return { ...state, zoom: payload };
    }

    default:
      return state;
  }
};

/*
{
type: 'SET_STATUS', payload: { importStatus: 'RUNNING' }
}
*/
